@import "../../../../branding/tele2/app-t2-defenition";
@import "MenuComponent";

.menu :global {

  .icon(){

  }
  
  .logo {
    display: none;
  }

  .item {
    color: @t2-color-universal-text-black-90-day;
  }

  .menu-content {
    .t2-main-width();
    .t2-card(@theme: 'day');
  }

  .colorizeIcon() {
    //filter: brightness(2); // gray to white
    filter: brightness(0);  // gray to black
  }
  
  .app-header .back-button {
    a {
      background-image: url("./t2/ico_close.svg");
      background-repeat: no-repeat;
      background-position: center center;
      .colorizeIcon();
    }
  }

  search {
    margin: 8px 16px 16px;

    /*form {
      background-color: @t2-color-universal-background-black-level-2-day;

      input {
        .placeholder(@t2-color-universal-text-black-60-day);
        color: @t2-color-universal-text-white-20-day;
      }

      &:hover {
        background-color: @t2-color-universal-background-black-level-3-day;
        input {
          color: @t2-color-universal-text-white-20-day;
        }
      }
    }*/
  }

  @menuHeight: 50px;

  .menu-item() {
    margin: 0;
    padding: 0;
    line-height: 50px;
    height: @menuHeight;

    .text {
      color: @t2-color-universal-text-black-90-day;
    }

    //border-bottom: 1px solid @t2-color-universal-background-white-level-2-day;
    border-bottom: 1px solid transparent;

    font-size: @t2-font-dim-h3-size--mobile !important;

    /*&:hover {
      border-bottom: 1px solid @t2-color-universal-background-black-level-3-day;
    }*/
  }

  .app-header {
    background: @t2-color-universal-background-white-level-1-day;
    margin-bottom: 0;

    .menu-button {
      left: 7px;
      right: auto;
      a {
        //.image-content-branding('./images/ico_back.svg');
        background-image: url('./t2/ico_back.svg');
        .colorizeIcon();
        //background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
      }
    }
  }

  /*.search {
    .menu-item();
    input {
      color: @karaoke-t2w-color-black;
      padding: 0 10px 0 18px;
    }
    button {
      .image-content-branding('./images/ico_header_search.svg');
      background-position: center;
      background-repeat: no-repeat;
      box-sizing: content-box;
      padding: 8px;
      right: 2px;
    }
  }*/

  .app-header-line {
    .menu-item();
    .text {
      .tele2-font-main-regular();
      //font-weight: 500;
      margin: 0 18px;
      padding: 0;
    }

    .note {
      padding: 2px 8px;
      margin: 2px 6px;
      line-height: 1.5em;

      &.open {
        display: block;
        //.image-content-branding("./images/ico_menu_item.svg");

        background-image: url('./t2/ico_menu_item.svg');
        //.colorizeIcon();
        background-size: 16px 16px;
        //background-size: cover;
        background-repeat: no-repeat;
        //background-position: center center;
        background-position: 100% 60%;

        top: 0;
        right: 0;
      }
    }
  }

  .item {
    .menu-item();
    .tele2-font-main-regular();
    span {
      font-size: 14px;
      margin: 0 18px;
      padding: 0;
    }
  }






/*  & .section-main,
  & .section-main:hover,
  & .section-main:active,
  & .section-catalog,
  & .section-catalog:hover,
  & .section-catalog:active,
  & .section-new,
  & .section-new:hover,
  & .section-new:active,
  & .section-popular,
  & .section-popular:hover,
  & .section-popular:active,
  & .item,
  & .item:hover,
  & .item:active,
  & .section-playlist,
  & .section-playlist:hover,
  & .section-playlist:active
  {
    // background-color: transparent;            https://image.shutterstock.com/image-photo/black-white-photo-male-musician-260nw-620531876.jpg
    border-color: @karaoke-t2w-color-white;
    background-color: @karaoke-t2w-color-white;
    color: @karaoke-t2w-color-black
  }*/


  background: @t2-color-universal-background-white-level-1-day;

  /*.item {
    .tele2-font-main-large();
  }*/

  .app-header .back-button {
    top: 4px;
    right: 7px;
    left: auto;
  }

  .app-header-line {
    position: relative;

    .text {
      color: @t2-color-universal-text-black-90-day;
    }

    .icon {
      display: none;
    }

    .note {
      position: absolute;
      top: 10px;
      right: 10px;
    }

    .open {
      background-image: data-uri('./t2/ico_arrow_down_menu.svg');
      //background-image: data-uri('./t2/ico_arrow_down_menu.svg');
      //background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
    }

    .close {
      background-image: data-uri('./t2/ico_arrow_up_menu.svg');
      background-repeat: no-repeat;
      background-position: center center;
      opacity: 1;
    }

    // margin: 10px -15px 0px;
  }

  /* .app-header-line {
  }*/



  /*.app-header-line {
    .open {
      //content: data-uri('./images/ico_arrow_down.svg');
      .image-content('./images/ico_arrow_down.svg');
    }

    .close {
      //content: data-uri('./images/ico_arrow_up.svg');
      .image-content('./images/ico_arrow_up.svg');
    }
  }*/

  /*& {
    .section-main { .app-header-line-menu-color(@section-main-bg-color, @section-main-border-color, @section-main-hover-bg-color, @section-main-hover-border-color); }
    .section-catalog { .app-header-line-menu-color(@section-catalog-bg-color, @section-catalog-border-color, @section-catalog-hover-bg-color, @section-catalog-hover-border-color); }
    .section-popular { .app-header-line-menu-color(@section-popular-bg-color, @section-popular-border-color, @section-popular-hover-bg-color, @section-popular-hover-border-color); }
    .section-playlist { .app-header-line-menu-color(@section-playlist-bg-color, @section-playlist-border-color, @section-playlist-hover-bg-color, @section-playlist-hover-border-color); }
    .section-new { .app-header-line-menu-color(@section-new-bg-color, @section-new-border-color, @section-new-hover-bg-color, @section-new-hover-border-color); }
    .section-feedback { .app-header-line-menu-color(@section-playlist-bg-color, @section-playlist-border-color, @section-playlist-hover-bg-color, @section-playlist-hover-border-color); }

    .app-header-line {
      &.section-main { .app-header-line-color(@section-main-bg-color, @section-main-border-color, @section-main-hover-bg-color, @section-main-hover-border-color); }
      &.section-catalog { .app-header-line-color(@section-catalog-bg-color, @section-catalog-border-color, @section-catalog-hover-bg-color, @section-catalog-hover-border-color); }
      &.section-popular { .app-header-line-color(@section-popular-bg-color, @section-popular-border-color, @section-popular-hover-bg-color, @section-popular-hover-border-color); }
      &.section-playlist { .app-header-line-color(@section-playlist-bg-color, @section-playlist-border-color, @section-playlist-hover-bg-color, @section-playlist-hover-border-color); }
      &.section-new { .app-header-line-color(@section-new-bg-color, @section-new-border-color, @section-new-hover-bg-color, @section-new-hover-border-color); }
      &.section-feedback { .app-header-line-color(@section-playlist-bg-color, @section-playlist-border-color, @section-playlist-hover-bg-color, @section-playlist-hover-border-color); }
    }
  }*/
}