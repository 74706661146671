@import "../../common/media";

.carousel-new() {

  max-width: 100vw;
  margin-left: -18px;
  margin-right: -18px;
  //position: relative;
  //height: 140px;

  display: flex;
  flex-wrap: nowrap;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;

  & > .content {
    display: flex;
    flex-wrap: nowrap;

    & > .item {
      flex: 0 0 auto;
    }
  }

  @media @desktop {
    display: none;
  }
}