@import "../../../../branding/tele2/app-t2-defenition";
@import "ButtonRowComponent";

button-row :global {

  .icon {
    margin-left: 12px;
    margin-right: 12px;
  }

}
