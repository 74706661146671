@import "../../../../branding/app-defenitions";

.page-title(
  @color-back,
  @color-logo,
  @color-text,
  @color-text-accent,
  @desktop-padding-right: @karaoke-dim-ident-page,
  @desktop-padding-left: 40px
) {
  background-color: @color-back;
  margin-bottom: 0; // reset app-header

  .logo {
    background: none;
    text-align: left;
    margin-left: 5px !important;

    &:before {
      content: "КАРАОКЕ";
      display: inline-block;
      font-weight: bold;
      font-size: 20px;
      color: @color-logo;
      letter-spacing: 0;
      text-align: left;
      line-height: 35px;
      vertical-align: middle;
      font-style: normal;

      background-repeat: no-repeat;
      background-position: center center;
    }
  }

  .text {
    color: @color-text;
  }

  .button {
    .text {
      color: @color-text;
      font-weight: lighter;
      font-style: normal;
    }
  }

  .menu-button, .back-button, .auth-button, .profile-button, .search-button, .lang-button {
    top: 3px;

    a {
      background-repeat: no-repeat;
      background-position: center center;
    }
  }

  .back-button {
    left: 8px;
    right: auto;
  }

  .menu-button {
    right: 7px;
    left: auto;
  }

  .profile-button, .auth-button {
    right: 50px;
  }

  .search-button {
    right: 90px;
  }

  @media @phone {
    .lang-button {
      display: none;
    }
  }

  @media @desktop {
    padding: 0 @desktop-padding-right 0 @desktop-padding-left;

    .link-wrapper {
      margin: 0;
    }

    .profile-button {
      .text {
        color: @color-text-accent;
      }
    }

    .overlay-search {
      padding: 4px @desktop-padding-right 0 @desktop-padding-left;
    }
  }
}
