@import "../../../../branding/tele2/app-t2-defenition";

.performance-response-selector :global {

  background-color: @karaoke-primary-accent-color;

  .app-label {
    margin-top: 6px;
    margin-left: 0;
  }

  /*.button-subscribe {
    .hide-internal-text();

    .button-image();

    margin-top: 7px;
    width: 170px;
    height: 30px;
  }*/

  .button {
    .component-button-primary();
  }

  @media @desktop {
    
    padding-left: 36px;
    padding-right: 36px;

    .button-block {
      margin: 6px 26px 6px 0;
    }

    .button {
      background-color: rgba(0, 0, 0, 0);
      border-color: #25272d;
      color: #25272d;

      &:after {
        color: #25272d;
      }
    }
  }
}