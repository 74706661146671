@import "../../../../branding/tele2/app-t2-defenition";
@import "SearchComponent";

search :global {

  @icon-size: 24px;

  .search {
    .component-input-text();

    [type="submit"] {
      background-image: url('./t2/ico_search.svg');
      background-size: @icon-size @icon-size;
      background-repeat: no-repeat;
      background-position: center center;

      left: 12px;
      top: 10px;
    }

    input {
      padding-left: 42px;
    }

    &.has-close {
      input {
        padding-left: 42px;
      }

      button[type="reset"] {
        background-image: url('./t2/ico_close.svg');
        //background-image: url('./images/ico_back.png');
        background-size: @icon-size @icon-size;
        background-repeat: no-repeat;
        background-position: center center;

        right: 12px;
        top: 12px;

      }
    }
  }

  /*.page-block.page-block-main & {
    .component-input-text();
  }*/
}

/*
.page-block.page-block-main search .search {
  .component-input-text();
}*/


/*
:global :local(search) {
  #menu & {
    margin-top: 8px;
  }
}*/
