@import "../../../../branding/tele2/app-t2-defenition";
@import "./DisclaimerComponent";

disclaimer :global {

  //display: block;
  //@background-color: "t2-color-universal-background-@{base}-level-1-@{theme}";
  //background-color: @@background-color;
  //text-align: center;
  .t2-background();

  text-align: center;
  display: block;

  .disclaimer {
    display: inline-block;
    box-sizing: border-box;
    .t2-main-width();

    //max-width: @page-content-max-width;
    //max-width: (@page-content-max-width - (@desktop-offset * 2) );
    background-color: transparent;

    .t2-card(1, @theme);
    margin: 0 auto;
    border-bottom: 0;
    border-top: 0;

    .app-label {
      .t2-text-on-card();
      text-align: left;
      margin-left: 0;
    }
  }
  max-width: 100%;
  overflow: hidden;

  @media @desktop {
    margin-bottom: -6px; // fix overflow
  }

  & {

    //padding: @dim-padding-lesser 0 0 0;
    .disclaimer {
      .t2-card(1, @theme/*, @mediaScope: @desktop*/, @margin: true);

      @media @desktop {
        margin-bottom: 0;
      }
      @media @phone {
        margin-bottom: 0;
      }
      //margin-top: 0;
      //margin-bottom: 0;

      background-color: transparent;
      z-index: 1;

      position: relative;

      .relative-internal() {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        z-index: -1;
      }

      // background
      &:before {
        .relative-internal();
        right: 0;
        left: 0;
        background-image: linear-gradient(180deg, #373737 0%, #758169 100%);
      }

      // art
      &:after {
        .relative-internal();
        right: 0;
        width: 100%;
        background-image: url("./t2/disclaimer-art.jpg");
        background-repeat: no-repeat;
        background-position: right bottom;
        background-size: contain;
      }

      .content {
        text-align: left;
        //width: 50%;
        .t2-card(1, @theme: 'day', @margin: false); // /*, @mediaScope: @desktop, */

        &:before {
          //content: "Пой с нами";
          content: "Мобильное Караоке";
          //.t2-font-header(@level: 1, @mediaScope: @desktop);
          .t2-font-header(@level: 1);
          padding-bottom: @dim-card-padding--mobile;
        }

        display: flex;
        flex-direction: column;
        justify-content: space-around;

        & > * {
          order: 1;
          .t2-text-on-card(@theme: 'day');

          padding-top: @dim-card-padding--mobile;
          &.center-block {
            order: 100;
          }
        }
      }

      .button-subscribe {
        .component-button-brand();
      }

      .app-label {
        padding: @dim-card-padding--mobile 0 0 0;
        &:first-child {
          padding: 0;
        }
      }

      .center-block {
        //float: left;
        margin: unset;
      }
    }
  }

  @media @desktop {
    .disclaimer {
      .t2-main-width-without-fields();
      &:after {
        width: 50%;
      }

      .content {
        width: 50%;
        min-height: 256px;
      }
    }
  }

  @media @phone {
    //margin: 0;
    //padding: 0;
    //box-sizing: border-box;
    
    .disclaimer {

      &:after {
        background-position: top right;
        background-repeat: no-repeat;
        background-size: auto 100%;
        //background-color: #373737;
      }

      .content {
        &:before {
          display: none;
        }

        margin-top: 164px;
      }
      //margin: 0;
      //padding: 0;
      //box-sizing: border-box;
    }
  }

}
