@import "../../../../branding/tele2/app-t2-defenition";

.battle-page :global {

  .t2-card();
  @height: 48px;

  .row {
    line-height: @height;

    .time {
      & when (@base = "white") {
        @color: "t2-color-universal-text-black-50-@{theme}";
        color: @@color;
      }

      & when (@base = "black") {
        @color: "t2-color-universal-text-white-30-@{theme}";
        color: @@color;
      }
    }

    .action.btn {
      .component-button-secondary(@height: @height);
      display: inline-block;
    }
  }
}