@import "../../../../branding/tele2/app-t2-defenition";
@import "./PageTitleComponent_tpl_v2_legacy";

@color-back: @karaoke-t2w-color-black;
@color-logo: @karaoke-t2w-color-white;
@color-text: @karaoke-t2w-color-white;
@color-text-accent: @karaoke-t2w-color-lightgray;

.page-title :global {

  .page-title(@color-back, @color-logo, @color-text, @color-text-accent, @desktop-padding-right: 52px);

  .bar-button(
    @link: true,
    @color-back-regular: @t2-color-interface-black-90-day,
    @color-text-regular: @t2-color-interface-white-100-day,
    @color-back-active: @t2-color-interface-black-70-day,
    @color-text-active: @t2-color-interface-white-08-day
  ) {
    .component-button(
      @color-back-regular: @color-back-regular;
      @color-text-regular: @color-text-regular;
      @color-back-active: @color-back-active;
      @color-text-active: @color-text-active;
      @compact: true;
      @height: 28px;
    );

    & when (@link = true) {
      .tele2-font-main-regular();
      color: @color-text-regular;
      text-transform: none;
    }

    & when (@link = false) {
      font-size: 0.85em;
    }

    padding-left: 12px;
    padding-right: 12px;
    border-radius: 6px;
  }

  // fix tpl2
  .button {
    .text {
      color: @color-text;
      font-weight: 400;
      font-style: unset;
    }
  }

  .link-wrapper {
    width: 152px;

    a.logo {
      padding: 1px 6px;
      //width: auto;
    }
  }

  .logo {
    &:before {
      //font-family: 'Tele2Stencil', arial, serif;
      .t2-font-logo();
    }
  }

  .logo {
    &:before {
      //font-family: 'Tele2Stencil', arial, serif;
      .t2-font-logo();
    }
  }

  .submenu > * {
    margin-left: 2px;
  }

  .text {
    .bar-button(@link: true);
  }

  .grayToWhite() {
    filter: brightness(2);
  }

  .back-button a {
    background-image: url('./t2/ico_back.svg');
    .grayToWhite();
  }

  .menu-button a {
    background-image: url('./t2/ico_menu.svg');
    .grayToWhite();
  }

  .profile-button a {
    background-image: url('./t2/ico_profile.svg');
    .grayToWhite();
  }

  .auth-button a {
    background-image: url('./t2/ico_auth.svg');
    .grayToWhite();
  }

  .search-button a {
    background-image: url('./t2/ico_header_search.svg');
    .grayToWhite();
  }

  .search-button.button a {
    background-position: center;
    /*display: block;
    height: 38px;*/
    min-width: 38px;
    .text {
      display: none;
    }
  }

  /*@media @desktop {
    .profile-button a {
      background-image: url('./t2/ico_profile_desktop.svg');
    }
  }*/

  /*@media @desktop-retina {
    .profile-button a {
      background-image: url('./t2/ico_profile_desktop.svg');
    }
  }*/

  /*@media @desktop {
    .search-button a {
      background-color: red;

      @media @retina {
        & {
          background-color: green;
        }
      }
    }
  }*/

  @media @desktop {
    & .button.auth-button, & .profile-button {
      .text {
        white-space: nowrap;
        max-width: unset !important;
        margin-top: 0 !important;
        margin-left: 0;
        line-height: 1.1em;

        .bar-button(
          @link: false;
          @color-back-regular: @t2-color-interface-white-100-day;
          @color-text-regular: @t2-color-interface-black-90-day;
          @color-back-active: @t2-color-interface-white-08-day;
          @color-text-active: @t2-color-interface-black-90-day;
        );
      }

      a {
        background-image: none;
      }
    }
  }
}

// hack to be able to add a global class to the css-module root
:global :local(.page-title) {
  &.event-ny {
    //background-color: mediumvioletred;

    .logo:after {
      content: '';
      display: block;

      position: absolute;
      bottom: -10px;
      right: 2px;

      background-repeat: no-repeat;
      background-position: top left;
      background-size: cover;

      height: image-height('./events/tele2-ny-socks.png');
      width: image-width('./events/tele2-ny-socks.png');
      background-image: url('./events/tele2-ny-socks.png');

      @media @retina {
        background-image: url('./events/tele2-ny-socks@2x.png');
      }
    }
  }
}
