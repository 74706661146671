@import "../../../../branding/tele2/app-t2-defenition";
@import "KarplayerComponent_tpl_v2";

.karplayer :global {

  @media @phone {
    .t2-card(@level: 1, @theme: 'day', @mediaScope: @phone, @margin: false);
    /*padding: 0;
    .passage-text {
      margin: @dim-padding;
    }*/

    .btn-primary {
      background-color: transparent;
    }
  }

  @media @desktop {
    //.t2-card(@level: 0, @theme: 'night', @mediaScope: @desktop, @margin: false, @padding: false);
    .t2-main-width-with-fields();
    .t2-main-width-without-fields();
  }

  .karplayer-impl();

  .t2-font-regular();
  background-color: @karaoke-t2w-color-white;

  .player-song-copyright > * {
    color: #adb0b5;
  }

  .player-content {
    .tele2-font-main-regular();
    .karplayer .kar-progress .kar-progress-begin, .karplayer .kar-progress .kar-progress-end {
      .tele2-font-main-small();
    }
  }

  .kar-text {
    color: @karaoke-t2w-color-black;

    // загрузили - приводим в начальное состояние
    &.ready {
      // пропеваемый блок
      .karaokeBlock.speakingBlock {
        span[data-begin].speaking {
          color: @karaoke-primary-accent-color;
        }

        span[data-begin].speaking.finish {
          color: @karaoke-primary-accent-color;
        }
      }
    }
  }

  .wrapper {
    position: relative;
    .siri-container {
      display: block;
    }
  }

  .kar-progress {
    .progress {
      background: @karaoke-search-border-color;

      .kar-progress-bar.progress-bar {
        background-color: @karaoke-primary-accent-color;
      }
    }

    .kar-progress-begin, .kar-progress-end {
      .tele2-font-main-small();
      color: @karaoke-t2w-color-black;
      text-shadow: #000011;
    }
  }

  .control-block.mobile {
    color: @karaoke-t2w-color-white;

    .category {
      .tele2-font-main-small();
      color: @karaoke-t2w-color-black;
    }

    .artist, .song {
      color: @karaoke-t2w-color-black;
    }

    .artist {
      .tele2-font-main-large();

      &:after {
        .tele2-font-main-large();
      }
    }

    .kar-button-main {
      width: image-width("./tele2/player_next.png");
      height: image-height("./tele2/player_next.png");

      .stop {
        background-image: url("./tele2/player_play.png");
      }

      .pause {
        background-image: url("./tele2/player_play.png");
      }

      .play {
        background-image: url("./tele2/player_stop.png");
      }

      .load {
        background-image: url("./tele2/player_wait.png");
      }
    }

    .play-next.btn, .play-next.btn:hover {
      width: image-width("./tele2/player_play.png");
      height: image-height("./tele2/player_play.png");
      background-image: url("./tele2/player_next.png");
    }

    .play-next.play-end, a.play-next.play-end:hover {
      background-image: url("./tele2/player_finish.png");
    }
  }


  .control-block.desktop {
    .media-body {
      .artist {
        .tele2-font-main-regular();
        .t2-font-header();
        color: @karaoke-search-placeholder-color;
      }

      .song {
        .tele2-font-main-regular();
        .t2-font-header();
        color: @karaoke-t2w-color-white;
      }
    }

    .play-end .pb-icon {
      background-image: url('./tele2/player_finish.png');
      filter: invert(1);
    }

    .play-next .pb-icon {
      background-image: url('./tele2/player_next.png');
      filter: invert(1);
    }

    .record .pb-icon.state-off {
      filter: invert(1);
    }
  }

  @media @desktop {
    .wrapper {
      background: @karaoke-t2w-color-black;
      padding-left: @dim-card-padding--desktop;
      padding-right: @dim-card-padding--desktop;
    }

    player-song-copyrights {
      color: @karaoke-t2w-color-lightgray;
      margin-left: 4px;
    }

    .kar-text {
      color: @karaoke-t2w-color-white;
    }
  }


  player-button {
    .pb-icon {
      &.state-stop {
        background-image: url("./tele2/player_play.png");
      }

      &.state-pause {
        background-image: url("./tele2/player_play.png");
      }

      &.state-play {
        background-image: url("./tele2/player_stop.png");
      }

      &.state-load {
        background-image: url("./tele2/player_wait.png");
        animation: fa-spin 2s infinite linear;
      }
    }

    .pb-label {
      .t2-font-header();
      color: #1F2229;
    }
  }

  player-button.sing .pb-icon {
    background-image: url("./tele2/player_sing.png");
  }

  player-button.download .pb-icon {
    background-image: url("./tele2/player_download.png");
  }

  player-button.ringtone .pb-icon {
    background-image: url("./tele2/player_ringtone.png");
  }

  player-button.record {
    .pb-icon {
      &.state-disabled {
        background-image: url("./tele2/recorder_disabled.png");
      }

      &.state-off {
        background-image: url("./tele2/recorder_disabled.png");
      }

      &.state-on {
        background-image: url("./tele2/recorder_enabled.png");
      }

      &.state-duet {
        background-image: url("./tele2/recorder_duet_enabled.png");
      }

      &.state-battle {
        background-image: url("./tele2/recorder_battle_enabled.png");
      }
    }
  }

  @media @desktop {

    player-button .pb-icon {

      &.state-stop {
        background-image: url("./tele2/player_play_desktop.png");
      }

      &.state-pause {
        background-image: url("./tele2/player_play_desktop.png");
      }

      &.state-play {
        background-image: url("./tele2/player_stop_desktop.png");
      }

      /*&.state-load {
        background-image: url("./tele2/player_wait.png");
        animation: fa-spin 2s infinite linear;
      }*/
    }
  }
}
