@import "../../../../../branding/tele2/app-t2-defenition";

picture-item :global {

  a {
    .t2-card(@level: 2);
    padding: 0;

    // fix
    &.cover:hover {
      box-shadow: @app-color-page-card-shadow;
    }
  }

  .catalog-item {

    .cover .description {
      padding: 10px;
    }

    .album {
      ///width: unset;
      margin: unset;
      padding: unset;
      //.component-button(@compact: true, @height: 42px);
      .component-button-brand(@height: 42px);
      display: inline-block;

      font-size: 0.85em;

      padding-left: @dim-step;
      padding-right: @dim-step;
      margin-bottom: @dim-padding;
    }
  }
}
