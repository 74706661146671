@import "../../../../branding/tele2/app-t2-defenition";

.player-song-progress :global {
  .progress {
    background: @karaoke-search-border-color;
    .progress-bar {
      background-color: @karaoke-primary-accent-color;
    }
  }

  .progress-begin, .progress-end {
    .tele2-font-main-small();
    color: @karaoke-t2w-color-black;
    text-shadow: #000011;
  }
}