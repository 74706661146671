@import "../../../../../branding/tele2/app-t2-defenition";
@import "SongsListComponent";

songs-list :global {
  .pagination > li:first-child > a {
    border-bottom-left-radius: @app-dim-button-border-radius;
    border-top-left-radius: @app-dim-button-border-radius;
  }

  .pagination > li:last-child > a {
    border-bottom-right-radius: @app-dim-button-border-radius;
    border-top-right-radius: @app-dim-button-border-radius;
  }
}
