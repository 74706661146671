@import "../../../../branding/tele2/app-t2-defenition";

.battle-status :global {

  .jumbotron {
    .font-page-title();
  }

  p {
    line-height: 24px;
  }

  input[type='tel'] {
    .component-input-text();
  }

  .btn {
    .component-button-primary();
    display: inline-block;
  }
}