@import "../../../../../branding/tele2/app-t2-defenition";

:global {
  :local(.song-item) {
    .content {
      padding: 5px 0 5px;
    }

    .play {
      margin-top: 6px !important
    }

    &.with-playlist .playlist-action {
      margin-top: 12px !important;
      margin-bottom: 0 !important;
    }
  }
}