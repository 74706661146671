@import "CarouselComponent";
@import "../../../../branding/tele2/app-t2-defenition";

:global :local(carousel) {
  .carousel-new();

  .cover {
    width: 200px;

    .t2-card(@level: 4, @margin: false);

    &:before {
      max-width: 100px;

      background-color: transparent;
      background-size: contain;
      background-position: left top;
    }

    background-color: transparent;
    background-size: contain;
    background-position: -20px top;

    .description {
      .t2-card(@level: 3, @margin: false);
      margin-left: 70px;

      border-radius: 12px;
      padding: 12px;
      height: 120px;
      top: 0;

      .title {
        font-size: @t2-font-dim-h5-size--mobile;
        line-height: @t2-font-dim-h5-line--mobile;
        //line-height: 1.1em;
        overflow-wrap: break-word;
        hyphens: auto;
      }

      .author {
        margin-top: (@t2-font-dim-h6-line--mobile);
        font-size: @t2-font-dim-h6-size--mobile;
        line-height: @t2-font-dim-h6-line--mobile;
        overflow-wrap: break-word;
        hyphens: auto;
      }
    }
  }

  & > .content > .item {
    margin-left: @dim-padding;
    
    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      padding-right: 0;
    }
  }
}
