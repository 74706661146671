@import "../../../../branding/tele2/app-t2-defenition";
@import "PromoComponent";

promo :global {

  text-align: left;

  .app-header-line.btn {
    .t2-text-on-card(@clickable: true);
  }

  @media @desktop {
    .t2-card(2, @theme, @mediaScope: @desktop);
    margin: @dim-card-margin-vertical--desktop 0;

    .watch-all {
      //.component-button-secondary();
      .component-button-brand();
      display: inline-block;
    }

    .app-header-line {
      text-align: left;
    }
  }

  @media @phone {

    .app-header-line.btn {
      //.component-button-secondary(@height: 32px);
      .component-button-brand(@height: @t2-font-dim-h1-size--mobile);
      margin: @dim-card-margin-vertical--mobile 0 !important;

      // fixes
      text-align: center;
      
      .icon, .arrow {
        display: none;
      }

      .text {
        margin-bottom: 0;
        .font-button(20px, @weight: 800);
      }
    }
  }

}
